export const Blik = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      className="blik"

      viewBox="0 0 135.6 64.2"
    >
      {/* Unique ID prefixes for gradients */}
      <defs>
        <linearGradient
          id="blik-gradient1"
          x1={-855.067}
          x2={-855.067}
          y1={15.931}
          y2={14.942}
          gradientTransform="matrix(133.983 0 0 62.525 114632.172 -933.17)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} style={{ stopColor: "#5a5a5a" }} />
          <stop offset={0.146} style={{ stopColor: "#484848" }} />
          <stop offset={0.52} style={{ stopColor: "#212121" }} />
          <stop offset={0.817} style={{ stopColor: "#080808" }} />
          <stop offset={1} style={{ stopColor: "#000" }} />
        </linearGradient>
        <linearGradient
          id="blik-gradient2"
          x1={-967.851}
          x2={-967.145}
          y1={61.38}
          y2={62.087}
          gradientTransform="rotate(-89.628 -7281.716 -6472.935) scale(14.176)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} style={{ stopColor: "#e52f08" }} />
          <stop offset={1} style={{ stopColor: "#e94f96" }} />
        </linearGradient>
      </defs>
      <path
        d="M127.7 0H7.9C3.5 0 0 3.5 0 7.9v48.4c0 4.4 3.5 7.9 7.9 7.9h119.8c4.4 0 7.9-3.5 7.9-7.9V7.9c0-4.4-3.5-7.9-7.9-7.9z"
        fill="#fff"
      />
      <path
        d="M127.7.8H7.9C4 .8.8 4 .8 7.9v48.4c0 3.9 3.2 7.1 7.1 7.1h119.8c3.9 0 7.1-3.2 7.1-7.1V7.9c0-3.9-3.2-7.1-7.1-7.1z"
        style={{ fill: "url(#blik-gradient1)" }}
      />
      <g transform="translate(20.254 6.714)">
        <circle cx={24.4} cy={8.2} r={7.1} fill="#fff" />
        <path
          d="M37.1 3.4H45v45h-7.9v-45zM52 18.9h8v29.4h-8V18.9zM16.5 18.6c-2.5 0-4.9.6-7.1 1.8v-17H1.5v30.2c0 8.3 6.7 15 15 15s15-6.7 15-15-6.7-15-15-15zm0 22.2c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.2 7.2-7.2 7.2z"
          fill="#fff"
        />
        <circle
          cx={24.4}
          cy={8.2}
          r={7.1}
          style={{ fill: "url(#blik-gradient2)" }}
        />
      </g>
      <path
        d="M106.297 54.997h10.2l-12.3-15.8 11.1-13.6h-9.3l-10.8 13.7v-29.2h-7.9v45h7.9v-15.7l11.1 15.6z"
        fill="#fff"
      />
    </svg>
  );
  