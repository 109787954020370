export const Visa = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      className="visa"
      viewBox="0 0 1000 323.7"
    >
      <path
        d="M433.4 319h-81.1L403 5.7h81z"
        style={{ fill: "#00579f" }}
      />
      <path
        d="M727 13.3C711 7 685.7 0 654.4 0c-80 0-136.3 42.7-136.7 103.7-.7 45 40.3 70 71 85 31.3 15.3 42 25.3 42 39-.3 21-25.3 30.7-48.6 30.7-32.3 0-49.7-5-76-16.7l-10.7-5L484 307c19 8.7 54 16.3 90.3 16.7 85 0 140.3-42 141-107 .3-35.7-21.3-63-68-85.3-28.3-14.3-45.7-24-45.7-38.7.3-13.3 14.7-27 46.7-27 26.3-.7 45.7 5.7 60.3 12L716 81l11-67.7z"
        style={{ fill: "#00579f" }}
      />
      <path
        d="M834.7 208c6.7-18 32.3-87.7 32.3-87.7-.3.7 6.7-18.3 10.7-30l5.7 27s15.3 75 18.7 90.7h-67.4zm100-202.3H872c-19.3 0-34 5.7-42.3 26L709.4 319h85s14-38.7 17-47h104c2.3 11 9.7 47 9.7 47h75L934.7 5.7z"
        style={{ fill: "#00579f" }}
      />
      <path
        d="m284.7 5.7-79.3 213.6-8.7-43.3C182 126 136 71.7 84.7 44.7l72.7 274H243l127.3-313h-85.6z"
        style={{ fill: "#00579f" }}
      />
      <path
        d="M131.7 5.7H1.3L0 12c101.7 26 169 88.7 196.7 164L168.3 32c-4.6-20-19-25.7-36.6-26.3z"
        style={{ fill: "#faa61a" }}
      />
    </svg>
  );
  