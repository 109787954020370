
import { useEffect, useRef, useState } from "react";
import { ArrowForwardIos400 } from "../../Icons/ArrowForwardIos400";
import { CheckIcon400 } from "../../Icons/CheckIcon400";
import { InfoIcon400 } from "../../Icons/InfoIcon400";
import { LogoNoText } from "../../Icons/LogoNoText";
import "./consultationsPanel.scss";

export const ConsultationsPanel = () => {

    const [askQuestionPanelActive, setAskQuestionPanelActive] = useState(false);

    const animatedDivRef = useRef<HTMLDivElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);
  

    const isInViewport = (element: HTMLElement): boolean => {
        const rect = element.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    useEffect(() => {
        const handleScroll = () => {
          if (animatedDivRef.current && isInViewport(animatedDivRef.current)) {
            setIsVisible(true);
            window.removeEventListener('scroll', handleScroll);
          }
        };
    
        window.addEventListener('scroll', handleScroll);

        handleScroll();
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleMenuTurn = (state: boolean) => {
        let html = document.querySelector('html');
        
        if(state) {
            setAskQuestionPanelActive(true);
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');
        } else {
            setAskQuestionPanelActive(false);
            if(html !== null) html.classList.remove('html-not-scrollable');
            document.body.classList.remove('body-not-scrollable');
        }
    } 


    return (
        <>
            {
                askQuestionPanelActive && 
                <div className="main-page__how-to-ask-question">
                    <div 
                        onClick={() => handleMenuTurn(false)}
                        className="main-page__cookie-panel__background">
                    </div>

                    <div className="main-page__how-to-ask-question__panel">
                        <h3>Zadawanie pytań do lekcji</h3>
                        <span className="main-page__how-to-ask-question__panel__text-1">
                            Masz problem ze zrozumieniem fragmentu lekcji lub rozwiązaniem zadania? 
                            <br/>
                            Napisz do nas ze swoim problemem na adres: 
                        </span>
                        <span className="main-page__how-to-ask-question__panel__email">
                            support@nasesje.com
                        </span>
                        <span className="main-page__how-to-ask-question__panel__text-2">
                            Nasz zespół odpowiada na zebrane pytania raz w tygodniu.
                        </span>
                        <div 
                        onClick={()=> handleMenuTurn(false)}
                        className="main-page__how-to-ask-question__panel__button-wrapper">
                            <button>zamknij</button>
                        </div>
                        
                    </div>

                </div>
            }
            <div className="main-page__consultations-panel">
                <div className="main-page__consultations-panel__top-section">
                    <h3>Nie jesteś sam <b>🙋</b></h3>
                    <div 
                        className="main-page__consultations-panel__top-section__color-wrapper"
                        ref={animatedDivRef}       
                    >
                        <button className="mobile" onClick={() => handleMenuTurn(true)}>
                            Jak zadać pytanie? <InfoIcon400/>
                        </button>
                        <span className="main-page__consultations-panel__top-section__text">
                        Pozostajesz pod stałą <b>opieką</b> twórców kursu.
                        </span>
                        <button className="desktop" onClick={() => handleMenuTurn(true)}>
                            Jak zadać pytanie? <InfoIcon400/>
                        </button>
                    </div>
                </div>
                <div className="main-page__consultations-panel__chat">
                    <div className={`main-page__consultations-panel__chat__window main-page__consultations-panel__chat__window--left displayed-first ${isVisible ? 'visible' : ''}`}>
                        <div className="main-page__consultations-panel__chat__window__text">
                            <span>przeczytano</span>
                            <CheckIcon400/>
                        </div>
                        
                        <span>Nie umiem zrobić zadania 😭</span>
                    </div>
                    <div className={`main-page__consultations-panel__chat__window main-page__consultations-panel__chat__window--right displayed-second ${isVisible ? 'visible' : ''}`}>
                        <LogoNoText/>
                        <span>Spokojnie, pomożemy ci!</span>
                    </div>
                </div>
            </div>
        </>
    )
}