
import "./discordInvitation.scss";
import { DiscordLogo } from "./DiscordLogo";

export const DiscordInvitation = () => {

    return <div className="main-page__discord-invitation">
        <div className="main-page__discord-invitation__left-section mobile-hidden">
            <DiscordLogo/>
        </div>
        <div className="main-page__discord-invitation__right-section">
            <div className="main-page__discord-invitation__right-section__separator"></div>
            <div className="main-page__discord-invitation__right-section__icon">
                <span className="main-page__discord-invitation__right-section__icon__hash">#</span>
                <div className="main-page__discord-invitation__left-section desktop-hidden">
                    <DiscordLogo/>
                </div>
                <span className="main-page__discord-invitation__right-section__icon__text">Dołącz do społeczności na discordzie <b>(nowość)</b></span>
            </div>
            <div className="main-page__discord-invitation__right-section__text">                
                <span className="text-1">Dołącz do społeczności na discordzie <b>(nowość)</b></span>
                <span className="text-2">Stwórzmy razem discorda na studia o jakim marzysz.</span>
            </div>
            <button onClick={() => window.open("https://discord.gg/vdnuPq8E65", "_blank")}>Dołącz do discorda</button>
        </div>
    </div>
}