
import { useEffect, useRef, useState } from "react";
import { CloseIcon400 } from "../../Icons/CloseIcon400";
import { InfoIcon400 } from "../../Icons/InfoIcon400";
import "./guarantee.scss";
import { CheckIcon400 } from "../../Icons/CheckIcon400";

import { AuthContext, AuthContextProps } from "../../../components/auth/authContext/AuthContextProvider";
import { registerEvent } from "../../../hooks/analytics/registerEvent";

interface GuaranteeProps {
    authContext: AuthContextProps;
    component: 'main page' | 'course preview';
}

export const Guarantee = (props: GuaranteeProps) => {
    const animatedDivRef = useRef<HTMLDivElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);
  
  
    const isInViewport = (element: HTMLElement): boolean => {
        const rect = element.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    useEffect(() => {
        const handleScroll = () => {
          if (animatedDivRef.current && isInViewport(animatedDivRef.current)) {
            setIsVisible(true);
            window.removeEventListener('scroll', handleScroll);
          }
        };
    
        window.addEventListener('scroll', handleScroll);

        handleScroll();
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getMainContainerClass = () => {

        const base = isVisible ? 'visible' : '';

        if(props.component === 'main page') {
            return "main-page__guarantee " + base;
        } else if (props.component === 'course preview') {
            return "main-page__guarantee coursePreview " + base;
        } else {
            return "";
        }
    }

   const [displayGuarantee, setDisplayGuarantee] = useState(false);

    const handleMenuTurn = (state: boolean) => {
        let html = document.querySelector('html');
        
        if(state) {
            setDisplayGuarantee(true);
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');
        } else {
            setDisplayGuarantee(false);
            if(html !== null) html.classList.remove('html-not-scrollable');
            document.body.classList.remove('body-not-scrollable');
        }
    } 

    return (
        <>
            {
                displayGuarantee && 
                <div className="main-page__guarantee__rules">
                    <div 
                        onClick={() => handleMenuTurn(false)}
                        className="main-page__guarantee__rules__background">
                    </div>

                    <div className="main-page__guarantee__rules__panel">
                        <h3>💸 Jak działa gwarancja?</h3>
                        <div className="main-page__guarantee__rules__panel__section">
                            <span className="main-page__guarantee__rules__panel__section__icon">
                            🔬
                            </span>
                            <span className="main-page__guarantee__rules__panel__section__text"> Testuj kurs do <b>7 dni</b> po zakupie.</span>
                        </div>
                        <div className="main-page__guarantee__rules__panel__section">
                            <span className="main-page__guarantee__rules__panel__section__icon">
                            🙅
                            </span>
                            <span className="main-page__guarantee__rules__panel__section__text"> Jeśli kurs ci się nie spodoba, uzupełnij formularz (wzór w regulaminie), a  <b>zwrócimy ci pieniądze</b>. </span>
                        </div>

                        <div className="main-page__guarantee__rules__panel__warning">
                            <span><b>Uwaga 🚨</b>: Nie możesz obejrzeć więcej niż 30% lekcji zawartych w kursie.</span>
                        </div>

                        <button onClick={() => handleMenuTurn(false)}>Zamknij</button>
                    </div>

                </div>
            }


            <div 
                className={getMainContainerClass()}
                ref={animatedDivRef}   
            >
                <div 
                    className="main-page__guarantee__sign">

                    <CheckIcon400/>
                </div>
                <div className="main-page__guarantee__money">
                    <span>💸</span>
                </div>
                <div className="main-page__guarantee__text">
                    <span className="main-page__guarantee__text__headline desktop">
                        7-dniowa gwarancja satysfakcji
                    </span>
                    <span className="main-page__guarantee__text__headline mobile">
                        7-dniowa gwarancja
                    </span>
                    <span  className="main-page__guarantee__text__description desktop">
                        Jeśli kurs nie spełni twoich oczekiwań, do 7 dni możesz dokonać zwrotu &nbsp;
                        <b
                            onClick={
                                () => {
                                    handleMenuTurn(true);

                                    registerEvent(
                                        props.authContext,
                                        "returns inf",
                                        "returns info",
                                        "returns info"
                                    )
                                }
                            }
                        >
                            zwrócimy ci pieniądze <InfoIcon400/></b>.
                    </span>
                    <span  className="main-page__guarantee__text__description mobile">
                        Jeśli kurs nie spełni twoich oczekiwań: &nbsp;
                        
                        <b onClick={
                            () => {
                                handleMenuTurn(true);

                                registerEvent(
                                    props.authContext,
                                    "returns inf",
                                    "returns info",
                                    "returns info"
                                )
                            }
                        }>
                            zwrócimy ci pieniądze <InfoIcon400/>
                        </b>.
                    </span>
                </div>
            </div>
        
        </>
    )
}