import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../main/MainContextProvider";
import { GlobalDataContext } from "../../../global/globalDataContext/GlobalDataContextProvider";
import { useTranslation } from "react-i18next";

import "../mainTest.scss";
import "./mainTestPreview.scss"

import { useNavigate, useParams } from "react-router-dom";
import { popLastElementFromUrl } from "../../../shared/utilities/url/url";
import { VideoOfferSolutionWrapper } from "../../additions/assignment/videoOfferSolutionWrapper/VideoOfferSolutionWrapper";
import { MainTestPreviewDto } from "../../../../../../shared/finalTest";
import { get, post } from "../../../../utilities/rest/apiClient";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";
import { BackArrowButton } from "../../../shared/components/titleWithBackButton/backArrowButton/BackArrowButton";
import { TitleWithBackArrowButton } from "../../../shared/components/titleWithBackButton/TitleWithBackArrowButton";

interface ScoreScreenProps {
    title: string;
    symbol: string;
    data: {name: string, num1: number, num2?: number}[]
}

const ScoreScreen = (props: ScoreScreenProps) => {

    const getRow = (name: string, key: number, num1: number, num2?: number) => {

        return (
            <div key={key} className="data-panel__final-test-preview__content-box__column__completion-score__row">
                
                <span>{name}:</span>
                <div>
                    {
                        (num2 === undefined) ? (
                            <>
                                <span className="colored">{num1}</span>
                                <span>%</span>
                            </>
                        ) : (
                            <>
                                <span className="colored">{num1}</span>
                                <span>{`/${num2}`}</span> 
                            </> 
                        )
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="data-panel__final-test-preview__content-box__column__completion-score">
            <div className="data-panel__final-test-preview__content-box__column__completion-score__headline">
                <span className="material-symbols-outlined">
                    {props.symbol}
                </span>
                <span>
                    {props.title}:
                </span>
            </div>
            {
                props.data.map((el, index) => getRow(el.name, index, el.num1, el.num2))
            }
        </div>
    )
}

export const MainTestPreview = () => {
    const mainCtx = useContext(MainContext);
    const globalCtx = useContext(GlobalDataContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [mainTestPreviewDto, setMainTestPreviewDto] = useState<MainTestPreviewDto>({} as MainTestPreviewDto);
    const [videoSolutionOpen, setVideoSolutionOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { courseId } = useParams();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        globalCtx.setIsMainTest(true);
        setIsLoading(true);
        mainCtx.setPath([globalCtx.title, `${t("kurs.testKoncowy.previewPage.finalTest")}`]); //TODO translate
        mainCtx.setActiveComponent("mainTest");

        (async ()=> {
            try {
              const data = await get<MainTestPreviewDto>(`courses/getFinalTestPreview/${courseId}`);
              setMainTestPreviewDto(data)
              setIsLoading(false);
            } catch(error) {
              authContext.handleAuthErrors(error);
              console.log(error);
              //TODO other errors
            }
          })()
    }, [])

    const handleClickTestButton = () => {
        navigate(popLastElementFromUrl());
    }

    const handleClickResetButton = () => {
        if(!mainTestPreviewDto.finished) return;

        // resetFinalTestProgress
        post<any, any>(`userCourses/resetFinalTestProgress/${courseId}`, {})
        .then((data) => {
            navigate(popLastElementFromUrl());
        })
        .catch(err => console.log(err));
    }

    const score1Data = [
        { 
            name: t("kurs.testKoncowy.previewPage.openAssignments"), 
            num1: mainTestPreviewDto.numberOfFinishedOpenQuestions,
            num2: mainTestPreviewDto.numberOfOpenQuestions
        },
        { 
            name: t("kurs.testKoncowy.previewPage.closedAssignments"), 
            num1: mainTestPreviewDto.numberOfFinishedClosedQuestions,
            num2: mainTestPreviewDto.numberOfClosedQuestions
        },
        { 
            name: t("kurs.testKoncowy.previewPage.fillGaps"), 
            num1: mainTestPreviewDto.numberOfFinishedPlaceholderQuestions,
            num2: mainTestPreviewDto.numberOfPlaceholderQuestions
        }
    ];

    const getPercentage = (numberOfCorrectExcercises: number, numberOfTotalExcercisesOfType: number) => {
        return Math.floor((numberOfCorrectExcercises / numberOfTotalExcercisesOfType) * 100);
    }

    const score2Data = [
        { 
            name: t("kurs.testKoncowy.previewPage.openAssignments"), 
            num1: getPercentage(mainTestPreviewDto.numberOfCorrectOpenQuestions, mainTestPreviewDto.numberOfOpenQuestions),
        },
        { 
            name: t("kurs.testKoncowy.previewPage.closedAssignments"), 
            num1: getPercentage(mainTestPreviewDto.numberOfCorrectClosedQuestions, mainTestPreviewDto.numberOfClosedQuestions),
        },
        { 
            name: t("kurs.testKoncowy.previewPage.fillGaps"), 
            num1: getPercentage(mainTestPreviewDto.numberOfCorrectPlaceholderQuestions, mainTestPreviewDto.numberOfPlaceholderQuestions),
        }
    ];

    if(isLoading){
        return <></>
    }
    return (
        <div className="data-panel__final-test-preview">
                <TitleWithBackArrowButton navigateTo={popLastElementFromUrl(popLastElementFromUrl())} title={t("kurs.nawigacja.backToCourseMenu")}/>
                <VideoOfferSolutionWrapper 
                    openAssignmentPossesion={mainTestPreviewDto.userBoughtVideoExplanations} 
                    videoSolutionOpen={videoSolutionOpen} 
                    setVideoSolutionOpen={setVideoSolutionOpen} 
                    assignmentNumber={0} 
                    assignmentsCount={0}
                    videoLink={mainTestPreviewDto.videoExplanationSrc}
                    isFinalTest={'preview'}/>
                {/* nazwa */}
                
                <div className="data-panel__final-test-preview__content-box">
                    <div className="data-panel__final-test-preview__content-box__column data-panel__final-test-preview__content-box__column--left">
                        <div className="data-panel__final-test-preview__content-box__column__headline data-panel__final-test-preview__content-box__column__headline--left-aligned">
                            <span>
                                {globalCtx.title + ` - ${t("kurs.testKoncowy.previewPage.finalTest")}`}
                            </span>
                        </div>

                        <div className="data-panel__final-test-preview__content-box__column__description">
                            {/* { TODO: remove dummy text} */}
                            <span>
                                Znajdujesz się w sekcji testu końcowego. Sprawdź swoje umiejętności rozwiązując test zawierający zadania z całego materiału {globalCtx.title}, który został omówiony podczas kursu. 
                            </span>
                            {/* {mainTestPreviewDto.description} */}
                        </div>

                        <div className="data-panel__final-test-preview__content-box__column__reset">
                            <div className="data-panel__final-test-preview__content-box__column__reset__headline">
                                <span>
                                    {t("kurs.testKoncowy.previewPage.redoYourTests")}
                                </span>
                            </div>
                            <div className="data-panel__final-test-preview__content-box__column__reset__description">
                                <span>
                                    {`${t("kurs.testKoncowy.previewPage.completing.after")} `}
                                    <b>{`${t("kurs.testKoncowy.previewPage.completing.completing")} `}</b>
                                    {t("kurs.testKoncowy.previewPage.completing.finalTest")}
                                </span>
                            </div>

                            <div className="data-panel__final-test-preview__content-box__column__reset__button-wrapper">
                                <button className={`not-selectable ${mainTestPreviewDto.finished ? "active" : "inactive"}`} onClick={handleClickResetButton}>
                                    <span className="material-symbols-outlined">
                                        replay
                                    </span>
                                    <span>
                                        {t("kurs.testKoncowy.previewPage.redoTest")}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="data-panel__final-test-preview__content-box__column data-panel__final-test-preview__content-box__column--right">
                        <div className="data-panel__final-test-preview__content-box__column__headline">
                            <span>
                                {t("kurs.testKoncowy.previewPage.yourTestScore")}:
                            </span>
                        </div>

                        <div className="data-panel__final-test-preview__content-box__column__results-info">
                            <span>
                                {t("kurs.testKoncowy.previewPage.yourScore")}
                            </span>
                        </div>
                        

                        {
                            <ScoreScreen symbol="check_circle" title="Ukończono" data={score1Data}/>
                        }
                        <div className="data-panel__final-test-preview__content-box__column__spacer"/>
                        {
                            <ScoreScreen symbol="verified" title="Poprawność zadań" data={score2Data}/>
                        }

                        <div className="data-panel__final-test-preview__content-box__column__notification">
                            <span>
                                <b>{t("kurs.testKoncowy.previewPage.warning.warning")}: </b> 
                                {t("kurs.testKoncowy.previewPage.warning.buttonClick")}
                                <b>{` "${t("kurs.testKoncowy.previewPage.warning.redoTest")}" `}</b> 
                                {t("kurs.testKoncowy.previewPage.warning.willCauseResultDelete")}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="data-panel__final-test-preview__buttons">
                    <button className="not-selectable" onClick={handleClickTestButton}>
                        <span className="material-symbols-outlined">
                            extension
                        </span>
                        <span>
                            {t("kurs.testKoncowy.previewPage.solveTest")}
                        </span>
                    </button>
                    
                    {/* <button className="not-selectable" onClick={() => setVideoSolutionOpen(true)}>
                        <span className="material-symbols-outlined">
                            videocam
                        </span>
                        <span>
                            {t("kurs.testKoncowy.previewPage.watchSolution")}
                        </span>
                    </button> */}

                </div>
            
        </div>
    );
}