var sha256 = require('js-sha256');

const ttqtrack = (eventName: string, args: any) => {
    (window as any).ttq.track(eventName, {
        ...args
    });
};

const hash = (message: string) => {
    message = message.toLowerCase().trim();
    var hash = sha256.create();
    hash.update(message);
    hash.hex();
    return hash.toString();
}

const ttqidentify = (email: string) => {
    (window as any).ttq.identify({
        email: email
    });
};

const fbqtrack = (eventName: string, args: any) => {
    (window as any).fbq('track', eventName, args);
}

export const ViewContent = (content_name: string, content_id: string) => {
    const args = {
        "content_name": content_name,
        "content_id": content_id
    }
    ttqtrack('ViewContent', args)
}

export const CompletePayment = (content_id: string, value: number, email: string) => {
    (window as any).gtag('event', 'purchase', { //tiktok event
        transaction_id: email + " " + Date.now().toString(),
        value: value,
        currency: 'PLN',
        user_data: {
            sha256_email_address: hash(email),
        },
        tt_external_id: hash(email),
        tt_content_type: 'product'
      });

    const facebook_args = {
        value: value, currency: 'PLN', content_ids: content_id
    }

    fbqtrack('Purchase', facebook_args);
}