import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { useContext, useEffect, useState } from "react";

import "../../App.scss";
import "./navbar.scss";
import 'material-symbols';

import { NavigationLink } from "./navigationLink/NavigationLink";
import { UserNav } from "./userPanel/UserNav";
import { DesktopElement } from "./desktopElement/desktopElement";
import { Logo } from "./logo/logo";
import { LanguageSelector } from "./languageSelector/LanguageSelector";
import { AuthContext } from "../auth/authContext/AuthContextProvider";
import { userInterfaceNotifications } from "shared/loggedInDto"; 
import { CloseIconNavbar } from "./closeIcon";

import { get } from "../../utilities/rest/apiClient";



interface NavbarProps {}

export const Navbar = (props: NavbarProps) => {
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const [selectedLanguage, setSelectedLanguage] = useState("PL");
    const [langActivePanel, setLangActivePanel] = useState(false);

    const [displaySeparator, setDisplaySeparator] = useState(true);

    const [discordInvitationActive, setDiscordInvitationActive] = useState(true);
    const [welcomingEmailActive, setWelcomingEmailActive] = useState(true);
    
    useEffect(() => {
        const parts = location.pathname.split('/');
        const subsite = parts[parts.length - 1];

        handleSeparatorDisplay(subsite);

    }, [location]);

    const handleSeparatorDisplay = (subsite: string) => {

        if(subsite === 'error' || subsite === 'notFound') {
            setDisplaySeparator(false);
        } else if(!displaySeparator) setDisplaySeparator(true);        
    }

    const handleMenuOpeningDesktop = async () => {
        if(menuOpen && initialized)
            setTimeout(() => setButtonVisible(true), 600); 

        if(!menuOpen)
            setButtonVisible(false);

        setMenuOpen(!menuOpen);

        if(!initialized)
            setInitialized(true);
    }

    const handleMenuOpeningMobile = () => {
        setMenuOpen(!menuOpen);
    }

    const desktopMenuElements = [
        {
            first: true,
            icon: "library_books",
            text: t("navbar.myCourses"),
            path: "userPanel"
        },
        {
            icon: "person",
            text: t("navbar.myAccount"),
            path: "userPanel/changeUsername"
        },
        {
            icon: "shopping_bag",
            text: t("navbar.coursesShop"),
            path: "shop"
        },
        {
            icon: "language",
            text: `${t("navbar.changeLanguage")} (${selectedLanguage})`,
            path: "language"
        },
        {
            last: true,
            icon: "logout",
            text: t("navbar.logout"),
            path: "logout"
        }
    ];

    const closeWelcomingEmail = () => {
        const userNotifications = {...authContext.userInterfaceNotifications};
        userNotifications.firstLoginNotificationDoNotShowAgain = true;
        authContext.setUserInterfaceNotifications(userNotifications);
        
        get<any>("userInterfaceNotifications/firstLoginNotificationDoNotShowAgainTurnOff")
        .then(() => {
            console.log("zamknięcie powiadomienia zostało zarejestrowane");
        }).catch((error) => {
            console.log("zamknięcie powiadomienia nie zostało zarejestrowane");
        });
    }

    const closeDiscordInfo = () => {
        const userNotifications = {...authContext.userInterfaceNotifications};
        userNotifications.discordServerNotificationDoNotShowAgain = true;
        authContext.setUserInterfaceNotifications(userNotifications);

        get<any>("userInterfaceNotifications/discordServerNotificationDoNotShowAgainTurnOff")
        .then(() => {
            console.log("zamknięcie powiadomienia o community zostało zarejestrowane");
        }).catch((error) => {
            console.log("zamknięcie powiadomienia o community nie zostało zarejestrowane");
        });
    }

    return (
        <header >
            <div id="navigation" className={displaySeparator? "" : "marginLess"}>

                <div id="navigation__logo-wrapper" onClick={() => navigate("/")}>
                    <Logo/>
                </div>

                <div id="navigation__user-panel">
                    {buttonVisible? <NavigationLink text={t("navbar.myCourses")} href={"userPanel"}/> : ""}
                    {buttonVisible? <LanguageSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} activePanel={langActivePanel} setActivePanel={setLangActivePanel}/> : "" }
                    <UserNav buttonVisible={buttonVisible} menuOpen={menuOpen} handleMenuOpeningDesktop={handleMenuOpeningDesktop} handleMenuOpeningMobile={handleMenuOpeningMobile}/>
                </div>                

            </div>

            <div className="notifications-wrapper">
            {
                !authContext.userInterfaceNotifications.firstLoginNotificationDoNotShowAgain && 
                <div className="password-information">
                    <div className="password-information__text-section">
                        <div className="password-information__text-section__hand">👋 </div>
                        <div className="password-information__text-section__text desktop">Twoje hasło zostało wysłane na podanego maila. &nbsp;Potrzebujesz pomocy? Napisz na: <b>support@nasesje.com</b> ✉️</div>

                        <div className="password-information__text-section__text mobile">Wysłaliśmy twoje hasło na maila. Pomoc: <b>support@nasesje.com</b></div>

                    </div>
                    
                    <div onClick={() => closeWelcomingEmail()}>
                        <CloseIconNavbar/>
                    </div>
                </div>
            }
            {
                !authContext.userInterfaceNotifications.discordServerNotificationDoNotShowAgain && 
                <div className={`discord-information`}>
                    <div className="discord-information__text-section">
                        <div className="discord-information__text-section__hand">👊 </div>
                        <div className="discord-information__text-section__text desktop" >Kliknij <a href="https://discord.gg/vdnuPq8E65">(tutaj)</a> i dołącz do naszej społeczności na discordzie i ucz się z innymi!</div>

                        <div className="discord-information__text-section__text mobile"><b>Kliknij</b> <a href="https://discord.gg/vdnuPq8E65">(tutaj)</a> i dołącz do społeczności na discordzie</div>

                    </div>
                    
                    <div onClick={() => closeDiscordInfo() }>
                        <CloseIconNavbar/>
                    </div>
                </div>
            }
            </div>



            <div className={`rolledUserMenu ${menuOpen? "rolledUserMenu--active": ""}`}>
                { 
                    desktopMenuElements.map(
                        (el, index) => { 
                            const element = <DesktopElement key={index} icon={el.icon} text={el.text} first={el.first} last={el.last} path={el.path} handleMenuOpeningMobile={handleMenuOpeningMobile}/>;
                            if(el.path !== "language") return element
                            else return React.cloneElement(element, { setLangActivePanel });
                        }
                    )
                }
            </div> 

        </header>
        
    )
}