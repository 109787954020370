import { useNavigate } from "react-router-dom";

import "./sellingCoursePanel.scss";
import "../../components/userPanel/userPanelPages/myCourses/coursePanel/CoursePanel.scss";

import { PlayCircle } from "../MainPage/graphics/PlayCircle";
import { ClockCircle } from "../MainPage/graphics/ClockCircle";

import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { BookIcon } from "../Icons/bookIcon";
import { PlayIcon } from "../Icons/PlayIcon";
import { handleCoursePageStatsRegister } from "../../utilities/pageStatsLogging";
import { useLocation } from 'react-router-dom';
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { Discount } from "shared/CourseDto";
import { getPriceAfter } from "../../utilities/pricing/discount";
import { SellIcon400 } from "../Icons/SellIcon400";


interface SellingCoursePanelProps {
    courseId: string;

    imgSrc: string;
    name: string;
    nameWithYearUpdated: string;
    
    description: string;
    numberOfHours: number;
    numberOfLessons: number;
    
    price: number;
    discount: Discount;
    lastUpdate: string;

    first: boolean;
    last: boolean;

    possesion?: boolean;

    coursePubliclyAvailable: boolean;
}

export const SellingCoursePanel = (props: SellingCoursePanelProps) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation(); 
    const authContext = useContext(AuthContext);
    
    const handleGoToCourseClick = () => {

        handleCoursePageStatsRegister(
            props.name,
            location.pathname,
            authContext.newUser,
            true,
            false
        );

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

        navigate(`/coursePreview/${props.courseId}`);

    }

    const handleViewLessonClick = () => {
        
        handleCoursePageStatsRegister(
            props.name,
            location.pathname,
            authContext.newUser,
            false,
            true
        );

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

        navigate(`/lessonPreview/${props.courseId}?fromLanding=true`);
    }

    const handleGoToMyCourse = () => {
        navigate(`/course/${props.courseId}/main`);
    }

    const getHeading = (name: string) => {
        const result: JSX.Element[] = [];
        const splitted = name.split(' ');

        const half = Math.ceil(splitted.length / 2);
        const firstHalfString = splitted.slice(0, half).join(' ');
        const secondHalfString = splitted.slice(half).join(' ');

        const firstHalfComponent = 
            <span key={0} className={prepName(true, firstHalfString)}>
                {firstHalfString}
            </span>

        const secondHalfComponent = 
            <span key={1} className={prepName(false, secondHalfString)}>
                {secondHalfString}
            </span>

        result.push(firstHalfComponent);
        result.push(secondHalfComponent);


        return result;
    }

    const prepName = (first: boolean, name: string) => {
        const baseName = "course-panel__main-name__text__element";
        let finalName = baseName + " " + baseName;
        finalName += (first ? "--first" : "--second") + " " + baseName;

        
        let fontSize = 27, potentialWidth = 1;
        const error = 5.0, expectedWidth = 140;

        if(calculatePotentialWidth(name, '24') <= 140) {
            fontSize = 24;
        } else {
            do {
                fontSize--;
                potentialWidth = calculatePotentialWidth(name, fontSize.toString());
            } while ( (Math.abs(expectedWidth - potentialWidth) > error) && (fontSize > 18) );
        }
        
        return finalName += `--${fontSize}`;
    }

    const calculatePotentialWidth = (name: string, size: string) => {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");

        let font = `600 ${size}px Arimo`;
        if(context === null) {
            return -1;
        } else {
            context.font = font;
            let metrics = context.measureText(name);
        
            return metrics.width;
        }
    }

    const handlePanelName = () => {
        const base = "course-panel selling-course-panel";

        const postfix = props.first ? "--first" : (props.last ? "--last" : "");
        const finalName = `${base} ${(postfix.length !== 0) ? "selling-course-panel" : ""}${postfix}`;

        return finalName;
    }
    
    return (
        <div className={handlePanelName()}>
            {
                props.discount.isActive && 
                <div className="selling-course-panel__discount-banner">
                    <span>- {props.discount.factor * 100}%</span>
                    <SellIcon400/>
                </div>
            }

            <div className="course-panel__main-name selling-course-panel__main-name">
                <div className="course-panel__main-name__img selling-course-panel__main-name__img">
                    <img
                    src={props.imgSrc}
                    alt="kurs"
                    />
                </div>
                <div className="course-panel__main-name__text selling-course-panel__main-name__text">
                    {getHeading(props.name)}
                </div>
            </div>
                <div className="course-panel__name">
                    <span>{props.nameWithYearUpdated}</span>
                </div>
                <div className="course-panel__description selling-course-panel__description">
                    <span>
                        {props.description}
                    </span>
                </div>

            <div className="course-panel__params selling-course-panel__params">
                <div className="course-panel__params__param selling-course-panel__params__param">
                    <PlayCircle/>
                    <span>+ {props.numberOfLessons} {t("kurs.preview.lessons")}</span>
                </div>
                <div className="course-panel__params__param selling-course-panel__params__param">
                    <ClockCircle/>
                    <span>+ {props.numberOfHours}h {t("kurs.preview.recordings")}</span>
                </div>
            </div>
            <div className="course-panel__content-wrapper selling-course-panel__content-wrapper">
                {
                    props.possesion ? (
                        <div className="course-panel__content-wrapper__button selling-course-panel__content-wrapper__button selling-course-panel__content-wrapper__button--centered" data-testid='go-to-course-button'
                            onClick={handleGoToMyCourse}>
                                <span> {t("landing.goToCourse")} </span>
                                <span className="material-symbols-outlined">
                                    arrow_forward
                                </span>
                        </div>
                    ) : (   
                        <>
                            <div className="selling-course-panel__content-wrapper__price">
                                {
                                    !props.discount.isActive ? 
                                        <span className="price">{`${props.price} zł`}</span> :
                                        <>
                                            <span className="small-price">{`${props.price} zł`}</span>
                                            <span className="discounted-price"> {`${getPriceAfter(props.discount, props.price)} zł`}</span>
                                        </>
                                }
                            </div>
                            <div className="course-panel__content-wrapper__button selling-course-panel__content-wrapper__button" data-testid='go-to-course-button'
                                onClick={handleGoToCourseClick}>
                                    <BookIcon/>
                                    <span> Zobacz kurs </span>
                                    
                            </div>
                        </>
                    ) 
                }
            </div>
            <div
                onClick={handleViewLessonClick}
                className="selling-course-panel__lesson-preview"
            >    
                <PlayIcon/>
                <span>Zobacz przykładową lekcję</span>

                {/* {props.coursePubliclyAvailable ? 
                <span>{`${t("landing.lastCourseUpdate")}: ${props.lastUpdate}`}</span> :
                <span>{`${t("landing.availableSoon")}`}</span>
                } */}
            </div>
        </div>
    );
}