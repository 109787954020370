
import { useEffect, useRef, useState } from "react";
import "./finalTestPreview.scss";


interface BoxProps {
    label: string;
    index: number;
    isVisible: boolean;
}

const Box = ({ label, index, isVisible }: BoxProps) => {
    return (
        <div className={`main-page__final-test-information__graphic__test__box ${label} ${(isVisible && (label === 'y')) ? 'active-yellow' : ''}`}>
            <span>{index + 1}</span>
        </div>
    );
};

export const FinalTestPreview = () => {

    const animatedDivRef = useRef<HTMLDivElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);
  
    const isInViewport = (element: HTMLElement): boolean => {
        const rect = element.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth;
      
        return (
          rect.top < windowHeight && 
          rect.bottom > 0 && 
          rect.left < windowWidth && 
          rect.right > 0 
        );
    };

    useEffect(() => {
        const handleScroll = () => {
          if (animatedDivRef.current && isInViewport(animatedDivRef.current)) {
            console.log('CHANGED TO VISIBLE');
            setIsVisible(true);

            window.removeEventListener('scroll', handleScroll);
          }
        };
    
        window.addEventListener('scroll', handleScroll);

        handleScroll();
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    const boxesDesktop = [
        "w", "w", "w", "l", "l", "l", "bw",
        "w", "l", "l", "l", "l", "l", "l",
        "l", "l", "l", "l", "b", "y", "l",
        "w", "w", "l", "lw", "l", "l", "l"
    ];

    const boxesMobile = [
        "w", "w", "w",   
        "w", "l", "y", 
        "l", "b", "l", 
        "w", "w", "l", 
    ];

    return (
        <div 
            className="main-page__final-test-information"
            ref={animatedDivRef}       
        >

            <div className="main-page__final-test-information__graphic">
                <div className={`main-page__final-test-information__graphic__pointer ${isVisible ? 'visible' : ''}`}>👆</div>
                <div className="main-page__final-test-information__graphic__text">
                    <span className="main-page__final-test-information__graphic__text__spacer"></span>
                    <span className="main-page__final-test-information__graphic__text__main">
                        Po &nbsp;ukończeniu &nbsp;kursu <br />
                        sprawdź swoją <br />
                        wiedzę &nbsp;w <br />
                        <b>teście końcowym</b>
                    </span>
                </div>

                <div className="main-page__final-test-information__graphic__test desktop">
                    {
                        boxesDesktop.map((label, index) => (
                            <Box label={label} index={index} key={index} isVisible={(label === 'y') ? isVisible : true}/>
                        ))
                    }
                </div>
                <div className="main-page__final-test-information__graphic__test mobile">
                    {
                        boxesMobile.map((label, index) => (
                            <Box label={label} index={index} key={index} isVisible={(label === 'y') ? isVisible : true} />
                        ))
                    }
                </div>

            </div>

        </div>
    );
};
